<template>
  <div class="card">
    <div
      v-if="hasHeader"
      class="card-header text-bg-dark bg-black"
    >
      <slot name="header"/>
    </div>

    <div
      v-if="hasDefault"
      class="card-body"
    >
      <slot/>
    </div>

    <slot name="raw"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UCard',

  computed: {
    hasHeader() {
      return this.$slots.header;
    },

    hasDefault() {
      return this.$slots.default;
    },
  },
});
</script>
